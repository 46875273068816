const CAROUSEL_DEFAULT_SETTINGS = {
  dots: true,
  arrows: false,
  autoplay: false,
  infinite: true,
  speed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  accessibility: true,
};

const BUBBLE_SPEECH_COLORS = {
  HippieBlue: '638fb8',
  Geraldine: 'fe8189',
  Viola: 'ce8dbd',
  Teal: '008a79',
};

const SIGNUP_FORM_ITEM_TYPE = {
  input: 'Sign Up Form Input',
  inputSelect: 'Sign Up Form Input Select',
  inputsGroup: 'Sign Up Form Inputs Group',
  accordion: 'Sign Up Form Accordion',
  rte: 'Sign Up Form RTE',
};

const INPUT_TYPE = {
  radio: 'radio',
  checkbox: 'checkbox',
};

const FIELD_ID = {
  tc: 'TC',
  newsletter: 'newsletter',
};

const CHECKBOX_DISAGREEMENT = 'I do not agree';
const INPUT_MAX_LENGTH = 255;
const SELECT_EMPTY_VALUE = 'Rather not say';
const NOT_PROVIDED_DATA = 'not provided';
const HOMEPAGE_PATH = '/';

enum FORM_TYPE {
  individual = 'individual',
  organization = 'organization',
}

export {
  BUBBLE_SPEECH_COLORS,
  CAROUSEL_DEFAULT_SETTINGS,
  CHECKBOX_DISAGREEMENT,
  FIELD_ID,
  FORM_TYPE,
  HOMEPAGE_PATH,
  INPUT_MAX_LENGTH,
  INPUT_TYPE,
  NOT_PROVIDED_DATA,
  SELECT_EMPTY_VALUE,
  SIGNUP_FORM_ITEM_TYPE,
};
