import React, { FC } from 'react';
import classNames from 'classnames';

import Image from 'common/Image';
import { BUBBLE_SPEECH_COLORS } from 'utils/constants';
import { toBoolean } from 'utils/functions';

import { SpeechBubbleProps } from './models';

import './SpeechBubble.scss';

const SpeechBubble: FC<SpeechBubbleProps> = ({ text, image, color, isReversed, isCardMode }) => {
  const contentClasses = classNames('speech-bubble__content', {
    'speech-bubble__content--color-hippie-blue': color.value === BUBBLE_SPEECH_COLORS.HippieBlue,
    'speech-bubble__content--color-geraldine': color.value === BUBBLE_SPEECH_COLORS.Geraldine,
    'speech-bubble__content--color-viola': color.value === BUBBLE_SPEECH_COLORS.Viola,
    'speech-bubble__content--color-teal': color.value === BUBBLE_SPEECH_COLORS.Teal,
  });

  return (
    <div className="speech-bubble">
      <div
        className={classNames('speech-bubble__container speech-bubble__container--top', {
          'speech-bubble__container--reverse': toBoolean(isReversed),
          'speech-bubble__container--card-mode': toBoolean(isCardMode),
        })}
      >
        <div className={contentClasses}>
          <span>{text}</span>
        </div>
      </div>
      <div
        className={classNames('speech-bubble__container speech-bubble__container--bottom', {
          'speech-bubble__container--reverse': toBoolean(isReversed),
          'speech-bubble__container--card-mode': toBoolean(isCardMode),
        })}
      >
        <div className={contentClasses}>
          <Image imageData={image} alt={image.altText} />
        </div>
      </div>
    </div>
  );
};

export default SpeechBubble;
