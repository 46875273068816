import { getLocationQueryStringParam } from './browser';

export const isEmpty = (obj): boolean => {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  if (typeof obj === 'object') {
    return !Object.keys(obj).length;
  }

  return !obj;
};

export const toBoolean = (value: string): boolean => Boolean(Number(value));

export default isEmpty;

export const cutLongText = (text: string, length: number, sign = '...'): string =>
  text.length > length + 1 ? text.substr(0, length) + sign : text;

const PAGE_URL_PARAM = 'page';
export const getCurrentPageFromUrl = () =>
  parseInt(getLocationQueryStringParam(PAGE_URL_PARAM, 'string'), 10) || 0;
