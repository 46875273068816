import React, { FC } from 'react';
import Slider from 'react-slick';
import { graphql, navigate } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import SpeechBubble from 'components/SpeechBubble';
import { CAROUSEL_DEFAULT_SETTINGS } from 'utils/constants';

import Button from '../Button';
import { CarouselProps } from './models';

import './carousel.scss';

const Carousel: FC<CarouselProps> = ({
  carouselImages: { carouselItems },
  className,
  settings = CAROUSEL_DEFAULT_SETTINGS,
  children,
}) => (
  <Slider
    {...settings}
    className={className}
    beforeChange={(_current, next) => {
      const isLastSlide = next % 1 !== 0;
      const slickDotsContainer = document.querySelector('.slick-dots');
      const slickDots = slickDotsContainer!.querySelectorAll('li');
      const slickDotsLength = slickDots!.length - 1;
      const lastSlickDot = slickDots[slickDotsLength];

      if (isLastSlide) {
        lastSlickDot.classList.add('slick-active');
      } else {
        lastSlickDot.classList.remove('slick-active');
      }
    }}
  >
    {children ||
      carouselItems.map(({ image, button, speechBubbles, customText }) => {
        const { ariaLabel, backgroundColor, label, size, link } = button || {};
        const { url } = link || {};

        return (
          <div key={image.altText}>
            <Image key={image.fallbackUrl} imageData={image} alt={image.altText} />
            {speechBubbles ? <SpeechBubble {...speechBubbles} /> : null}
            {button ? (
              <Button
                className={classNames('btn--sign-up', { 'btn--top-margin': customText })}
                ariaLabel={ariaLabel!}
                backgroundColor={backgroundColor}
                size={size}
                type="button"
                onClick={() => navigate(url!)}
                label={label}
              />
            ) : null}
            {customText ? (
              <DangerouslySetInnerHtml className="custom-text" html={customText} />
            ) : null}
          </div>
        );
      })}
  </Slider>
);

export const CarouselItemsType = graphql`
  fragment CarouselItemsType on CarouselItemsType {
    image {
      ...UmbracoImage
    }
    customText
    button {
      ariaLabel
      backgroundColor
      label
      link {
        url
      }
      size
    }
    speechBubbles {
      text
      image {
        ...UmbracoImage
      }
      color {
        value
      }
      isReversed
      isCardMode
    }
  }
`;

export default Carousel;
