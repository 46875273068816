import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import { ToBooleanFunctionType, ToPascalCaseFunctionType } from './models';

export const toBoolean: ToBooleanFunctionType = (str) => Boolean(Number(str));

export const toPascalCase: ToPascalCaseFunctionType = (str) =>
  startCase(camelCase(str)).replace(/ /g, '');

const stringUtils = {
  toPascalCase,
};

export default stringUtils;
