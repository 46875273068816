import React from 'react';
import { navigate } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import SocialMedia from 'components/SocialMedia';
import { toBoolean } from 'utils/stringUtils/stringUtils';

import './InfoBanner.scss';

const InfoBanner = ({ infoBanner }: PageContent.InfoBanner) => {
  const { title, infoButton, description, reverse, socialMediaLinks } = infoBanner || {};
  const { ariaLabel, backgroundColor, size, label, link } = infoButton || {};
  const { url } = link || {};

  return infoBanner ? (
    <div
      className={classNames('info-banner', {
        'info-banner__reverse': toBoolean(reverse),
      })}
      data-testid="info-banner"
    >
      {title ? <h2 className="title">{title}</h2> : null}
      {infoButton ? (
        <Button
          className="btn--sign-up"
          ariaLabel={ariaLabel}
          backgroundColor={backgroundColor}
          size={size}
          type="button"
          onClick={() => navigate(url!)}
          label={label}
        />
      ) : null}
      <div className="description">
        <DangerouslySetInnerHtml html={description} data-testid="info-description" />
        {socialMediaLinks ? <SocialMedia socialMediaLinks={socialMediaLinks} /> : null}
      </div>
    </div>
  ) : null;
};

export default InfoBanner;
